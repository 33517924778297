<template>
  <v-app>
    <v-sheet>
      <!-- {{isLoggedIn}} -->
      <!-- <v-overlay :value="!isLoggedIn" opacity="1" color="#ccf3ff" absolute z-index="5">
        {{isLoggedIn}}
        {{navigationLinks.length}}
        {{currentUser}}
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay> -->
    </v-sheet>
    <div v-if="$store.getters.showNav2">
    <v-navigation-drawer
      v-if="isLoggedIn && navigationLinks.length > 0"
      permanent
      style="height: 100vh; overflow-y:scroll;"
      color="primary"
      app
      :hide-overlay="true"
      overlay-opacity="1"
      overlay-color="white"
      dark
      expand-on-hover
    >
      <v-list-item class="px-2">
        <!-- <v-list-item-avatar>
        </v-list-item-avatar> -->
          <v-img
            src="logo.png"
            max-height="50"
            max-width="30"
            contain
          ></v-img>

        <v-list-item-title class="title text-center">{{currentUser.Name}} <img src="./assets/telegram.png" v-if="currentUser.TelegramID !== 0" width="20px" alt=""></v-list-item-title>
        <v-list-item-action>
          <v-btn
          icon
          fab
          small
          @click="logout"
          class="mr-7"
          >
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in navigationLinks"
          :key="item.title"
          link
          active-class="priamry"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- <div id="app">
      <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link><span v-if="isLoggedIn"> | <a @click="logout">Logout</a></span>
      </div>
      <router-view/>
    </div> -->
    </div>
    <v-main>
      <router-view :key="$router.fullPath" :users="users" :currentUser="currentUser"></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    drawer: false,
    mini: false,
    users: [],
    currentUser: {},
    nav_items: [
      { title: 'New Ticket', icon: 'mdi-clipboard-plus-outline', link: '/newticket', access: 'All' },
      { title: 'My Requests', icon: 'mdi-account-circle-outline', link: '/tickets/My Requests', access: 'All' },
      { title: 'Assigned To Me', icon: 'mdi-account-star-outline', link: '/tickets/Assigned To Me', access: '1' },
      // { title: 'To Department', icon: 'mdi-clipboard-arrow-left-outline', link: '/tickets/To Department', access: '1' },
      { title: 'From Department', icon: 'mdi-clipboard-arrow-right-outline', link: '/tickets/From Department', access: '1, admin' },
      { title: 'All Tickets', icon: 'mdi-clipboard-list-outline', link: '/tickets/All Tickets', access: '1' },
      { title: 'Manage Users', icon: 'mdi-format-list-checks', link: '/manageusers', access: 'admin' },
      { title: 'Manage Locations', icon: 'mdi-map-marker-outline', link: '/locations', access: '1' },
      // { title: 'Location Status', icon: 'mdi-map-marker-check-outline', link: '/locationstatus' },
      { title: 'Groups', icon: 'mdi-account-group-outline', link: '/groups', access: '1' },
      { title: 'Ticket Types', icon: 'mdi-format-list-bulleted-type', link: '/tasktypes', access: '1' }
    ],
    navigationLinks: []
  }),
  computed: {
    isLoggedIn: function () { return this.$store.getters.isLoggedIn }
  },
  watch: {
    $route: function () {
      this.getData()
    }
  },
  methods: {
    getData () {
      this.$http.get(this.$url + '/api/user')
        .then((response) => {
          this.users = response.data.data.filter(item => item.UserStatus === 1)
        }, (err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
          }
          throw err
        })
      this.$http.get(this.$url + '/api/currentuser')
        .then((response) => {
          this.currentUser = response.data.data
          this.navigationLinks = this.nav_items.filter(item => {
            if (item.access.includes(this.currentUser.GroupID) || item.access.includes('All')) {
              return item
            }
            if (this.currentUser.IsAdmin) {
              return item.access.includes('admin')
              // console.log('hello')
              // return item
            }
          })
          if (response.data.data.ChangeAtNextLogon) {
            this.$store.commit('toggleActive', false)
          } else {
            this.$store.commit('toggleActive', true)
          }
        })
    },
    logout () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
    }
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }
        throw err
      })
    })
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');
$title-font: 'Nunito', sans-serif;
.v-application {
  // font-family: $body-font-family, sans-serif !important;
  .title, .text-h5, .text-h4, .v-card__title { // To pin point specific classes of some components
      font-family: $title-font, sans-serif !important;
  }
}
.bgColor {
  background-color: #ffe6de
}
</style>
