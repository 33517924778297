import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/newticket',
    name: 'NewTicket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewTicket.vue')
  },
  {
    path: '/tickets/:type',
    name: 'Tickets',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tickets.vue')
  },
  {
    path: '/singleticket/:id',
    name: 'SingleTicket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SingleTicket.vue')
  },
  {
    path: '/locations',
    name: 'Locations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Locations.vue')
  },
  {
    path: '/groups',
    name: 'Groups',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Groups.vue')
  },
  {
    path: '/manageusers',
    name: 'ManageUsers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageUsers.vue')
  },
  {
    path: '/tasktypes',
    name: 'TaskTypes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TaskTypes.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewTicket.vue')
  }
]

const router = new VueRouter({
  routes
})

function requireAuth (to, from, next) {
  // if (to.name !== 'Login') {
  //   router.app.$http.get('/api/user')
  //     .then((response) => {
  //       const user = response.data.data
  //       router.app.$emit('user', user)
  //       if (user.username === undefined) {
  //         document.location = '/login/#/?next=' + document.location.pathname
  //       } else {
  //         next()
  //       }
  //     }, (error) => {
  //       if (error.response && error.response.status === 403 && error.response.data.detail === 'Authentication credentials were not provided.') {
  //         console.log('User API error')
  //       } else {
  //         // document.location = '/login/#/?next=' + document.location.pathname
  //         router.push('/login')
  //       }
  //       next()
  //     })
  // } else {
  //   next()
  // }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else if (to.name !== 'Login') {
    router.app.$http.get(router.app.$url + '/api/currentuser').then(response => {
      const user = response.data.data
      if (user.ChangeAtNextLogon) {
        // document.location = '/login/#/?next=' + document.location.pathname
        router.push('/login')
      } else {
        next()
      }
    }, error => {
      if (error.response && error.response.status === 403 && error.response.data.detail === 'Authentication credentials were not provided.') {
        // console.log('User API error')
      } else {
        // document.location = '/login/#/?next=' + document.location.pathname
        router.push('/login')
      }
      next()
    })
  } else {
    next()
  }
}

router.beforeEach(requireAuth)

export default router
